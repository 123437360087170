import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
// import logo from './logo.svg';
import './App.css';

function App() {
  const products = [
    {
      name: 'WWD55BLACK',
      weight: 3.11
    },
    {
      name: 'WWD55INOX',
      weight: 2
    },
    {
      name: 'WWD100BLACK',
      weight: 5.58
    },
    {
      name: 'WWD100INOX',
      weight: 3.06
    },
    {
      name: 'WWD200INOX',
      weight: 6.34
    },
    {
      name: 'SUMATOR',
      weight: 0.55
    },
    {
      name: 'SYSTEM RADIOWY',
      weight: 0.6
    },
    {
      name: 'ZASILACZ 25W',
      weight: 0.68
    },
    {
      name: 'ZASILACZ 50W',
      weight: 1.17
    }
  ];

  const [weight, setWeight] = useState(0);

  const calculateWeight = addedWeight => setWeight(weight + addedWeight);

  return (
    <div className="wrapper">
      <div className="products-list">
        {products.map(item => (
          <Product
            name={item.name}
            weight={item.weight}
            calculateWeight={calculateWeight}
          />
        ))}
      </div>
      <div className="result">
        <Result weight={weight} />
      </div>
    </div>
  );
}

function Product({ name, weight, calculateWeight }) {
  const [quantity, setQuantity] = useState(0);

  const minusClickHandler = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
      calculateWeight(-weight);
    }
  };

  const plusClickHandler = () => {
    setQuantity(quantity + 1);
    calculateWeight(weight);
  };

  return (
    <div className="product">
      <span className="product__name">{name}</span>
      <div className="product__buttons">
        <Minus clickHandler={minusClickHandler} />
        <span className="product__quantity">{quantity}</span>
        <Plus clickHandler={plusClickHandler} />
      </div>
    </div>
  );
}

function Minus({ clickHandler }) {
  return (
    <button className="button button--minus" onClick={clickHandler}>
      <i className="fas fa-minus fa-sm"></i>
    </button>
  );
}

function Plus({ clickHandler }) {
  return (
    <button className="button button--plus" onClick={clickHandler}>
      <i className="fas fa-plus fa-sm"></i>
    </button>
  );
}

function Result({ weight }) {
  let boxWeight = 0.5;
  let bruttoWeight = Math.ceil(weight + boxWeight);
  if (weight > 0.001)
    return (
      <>
        <span>Waga paczki: {bruttoWeight} kg</span>
        <span data-tip="copy" className="result__copy">
          <CopyToClipboard text={bruttoWeight}>
            <i class="far fa-copy"></i>
          </CopyToClipboard>
        </span>
        <ReactTooltip place="top" effect="solid" >
          <span className="result__tooltip">Skopiuj wagę paczki do schowka</span>
        </ReactTooltip>
      </>
    );
  else return 'Dodaj produkty do paczki...';
}

export default App;
